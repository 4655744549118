<template>
	<div>
		<ContentHeader title="Data Pelanggan" subTitle="Edit Data Pelanggan" url="/data-pelanggan" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
          <div class="col">
          	<div class="card">
          		<div class="card-header row">
          			<h3 class="card-title">Edit Data Pelanggan</h3>
          		</div>
          		<div class="card-body">
          			<form @submit.prevent="prosesUpdatePelanggan">
          				<div class="px-lg-2 ">
		                  <div class="form-group">
		                    <label for="nama">Nama <span class="text-danger">*</span></label>
		                    <input type="text" class="form-control" id="nama" autocomplete="off" name="nama" required="" v-model="pelanggan.nama">
		                  </div>
			                <div class="form-group">
		                    <label for="alamat">Alamat <span class="text-danger">*</span></label>
		                    <textarea class="form-control" style="height: 100px;" id="alamat" required="" name="alamat" v-model="pelanggan.alamat"></textarea>
		                  </div>
		                  <div class="form-group">
		                    <label for="telepon">Telepon <span class="text-danger">*</span></label>
		                    <input type="tel" class="form-control" id="telepon" autocomplete="off" name="telepon" required="" v-model="pelanggan.telepon">
		                  </div>
		                </div>
		                <button type="submit" class="btn btn-primary d-flex align-items-center ml-2 mt-2">
										  <div>
										  	Submit
										  </div>	
										  <div>
		                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
		                      <g>
		                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
		                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
		                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
		                      </g>

		                    </svg>
		                  </div>
										</button>
          			</form>
          		</div>
          	</div>
          </div>
		    </div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'

	import { ref, onMounted } from 'vue'
	import axios from 'axios'
	import router from '@/router'
	import Swal from 'sweetalert2'
	import { useRoute } from 'vue-router'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup () {
			const loading = ref(false)
			const route = useRoute()
			const pelanggan_id = route.params.id
			const pelanggan = ref([])

			const getPelanggan = async () => {
				let { data } = await axios.get(`api/pelanggan/getWhereId/${pelanggan_id}`)
				console.log(data)
				pelanggan.value = data 
			}

			const prosesUpdatePelanggan = () => {
				loading.value = true
				axios.put(`api/pelanggan/update/${pelanggan_id}`, pelanggan.value)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Mengedit Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
					console.log(response)
					router.replace('/data-pelanggan')
				})
				.catch((error) => {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal Mengedit Data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})	
					loading.value = false
					console.log(error)
				})

			}

			onMounted(() => {
				getPelanggan()
			})

			return {
				loading, pelanggan, prosesUpdatePelanggan
			}
		}
	}
</script>